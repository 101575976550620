import React from 'react';
import './Features.scss';
import Affordable from '../../assets/images/affordable.png';
import Fast from '../../assets/images/fast.png';
import Mpesa from '../../assets/images/mpesa.png';
import Secure from '../../assets/images/secure.png';
import Support from '../../assets/images/support.png';
import Wallet from '../../assets/images/wallet.png';

const Features = () => {
    const featureIcons = {
        wallet: Wallet,
        mpesa: Mpesa,
        secure: Secure,
        lowFees: Affordable,
        conversion: Fast,
        support: Support,
    };

    const features = [
        {
            title: 'Crypto Wallet Integration',
            description: 'Easily connect your crypto wallet to Elements Pay for seamless transactions.',
            icon: 'wallet',
        },
        {
            title: 'M-Pesa Compatibility',
            description: 'Make payments directly to M-Pesa from your crypto assets, without any hassle.',
            icon: 'mpesa',
        },
        {
            title: 'Secure Transactions',
            description: 'Enjoy end-to-end encryption and secure transfers with advanced security protocols.',
            icon: 'secure',
        },
        {
            title: 'Instant Conversion',
            description: 'Convert crypto to local currency in real-time for immediate M-Pesa transactions.',
            icon: 'conversion',
        },
        {
            title: 'Low Fees',
            description: 'Benefit from low transaction fees, making everyday payments affordable and convenient.',
            icon: 'lowFees',
        },
        {
            title: '24/7 Support',
            description: 'Access our dedicated support team anytime to resolve any issues you might have.',
            icon: 'support',
        },
    ];

    return (
        <section className="features">
            <div className="container">
                <div className="features__grid">
                    {features.map((feature, index) => (
                        <div key={index} className="features__item">
                            <img src={featureIcons[feature.icon]} alt={feature.title} className="features__icon" />
                            <h3 className="features__title">{feature.title}</h3>
                            <p className="features__description">{feature.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Features;
