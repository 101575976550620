import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGooglePlay } from '@fortawesome/free-brands-svg-icons';
import './Hero.scss';

const Hero = () => {
    const particlesRef = useRef(null);
    const titleRef = useRef(null);
    const subtitleRef = useRef(null);
    const descriptionRef = useRef(null);
    const ctaGroupRef = useRef(null);

    const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 1200);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const loadParticles = async () => {
            if (typeof window !== 'undefined' && !window.particlesJS) {
                await import('particles.js');
            }

            window.particlesJS('particles-js', {
                particles: {
                    number: {
                        value: windowWidth < 768 ? 40 : 80,
                        density: { enable: true, value_area: 800 }
                    },
                    color: { value: "#00E676" },
                    shape: { type: "circle", stroke: { width: 0, color: "#000000" }, },
                    opacity: { value: 0.5, random: false, },
                    size: { value: windowWidth < 768 ? 2 : 3, random: true, },
                    line_linked: {
                        enable: true,
                        distance: windowWidth < 768 ? 100 : 150,
                        color: "#00E676",
                        opacity: 0.4,
                        width: 1
                    },
                    move: {
                        enable: true,
                        speed: windowWidth < 768 ? 4 : 6,
                        direction: "none",
                        random: false,
                        straight: false,
                        out_mode: "out",
                        bounce: false,
                    },
                },
                interactivity: {
                    detect_on: "canvas",
                    events: {
                        onhover: { enable: true, mode: "repulse" },
                        onclick: { enable: true, mode: "push" },
                        resize: true
                    },
                    modes: {
                        repulse: { distance: windowWidth < 768 ? 100 : 200, duration: 0.4 },
                        push: { particles_nb: windowWidth < 768 ? 2 : 4 }
                    },
                },
                retina_detect: true
            });
        };

        loadParticles();
    }, [windowWidth]);

    useEffect(() => {
        // GSAP animation timeline
        const tl = gsap.timeline({ defaults: { ease: 'power3.out' } });

        tl.fromTo(particlesRef.current, { opacity: 0 }, { opacity: 1, duration: 1 })
            .fromTo(titleRef.current, { y: 50, opacity: 0 }, { y: 0, opacity: 1, duration: 0.8 }, '-=0.5')
            .fromTo(subtitleRef.current, { y: 50, opacity: 0 }, { y: 0, opacity: 1, duration: 0.8 }, '-=0.6')
            .fromTo(descriptionRef.current, { y: 50, opacity: 0 }, { y: 0, opacity: 1, duration: 0.8 }, '-=0.6')
            .fromTo(ctaGroupRef.current.children, { y: 50, opacity: 0 }, { y: 0, opacity: 1, duration: 0.8, stagger: 0.2 }, '-=0.6');

    }, []);

    return (
        <section className="hero">
            <div id="particles-js" ref={particlesRef}></div>
            <div className="container">
                <h1 className="hero__title" ref={titleRef}>
                    Seamless Payments, Anytime<br />
                    <span className="hero__title--highlight">Crypto to M-Pesa Made Easy</span>
                </h1>
                <p className="hero__subtitle" ref={subtitleRef}>
                    Your gateway to effortless payments.
                </p>
                <p className="hero__description" ref={descriptionRef}>
                    Use your crypto assets to pay directly with M-Pesa. Fast, secure, and reliable transactions
                    at your fingertips. Connect your wallet, convert your assets, and make payments instantly.
                </p>
                <div className="hero__cta-group" ref={ctaGroupRef}>
                    <button className="hero__cta hero__cta--primary">
                        <FontAwesomeIcon icon={faGooglePlay} className="hero__cta-icon" />
                        Get the App
                    </button>
                    <button className="hero__cta hero__cta--secondary">Learn more</button>
                </div>
            </div>
        </section>
    );
};

export default Hero;