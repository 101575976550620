import React from 'react';
import './Footer.scss';
import Logo from '../../assets/icons/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container footer__container">
                <div className="footer__main">
                    <div className="footer__brand">
                        <img src={Logo} alt="Elements Pay Logo" className="footer__logo" />
                        <p className="footer__tagline">Effortless Crypto Payments with M-Pesa</p>
                    </div>
                    <nav className="footer__nav">
                        <div className="footer__nav-column">
                            <h4>Features</h4>
                            <ul>
                                <li><a href="#wallet-integration">Crypto Wallet Integration</a></li>
                                <li><a href="#mpesa-compatibility">M-Pesa Compatibility</a></li>
                                <li><a href="#security">Secure Transactions</a></li>
                                <li><a href="#instant-conversion">Instant Conversion</a></li>
                            </ul>
                        </div>
                        <div className="footer__nav-column">
                            <h4>Support</h4>
                            <ul>
                                <li><a href="#faq">FAQs</a></li>
                                <li><a href="#contact">Contact Support</a></li>
                                <li><a href="#guides">User Guides</a></li>
                                <li><a href="#community">Community Forum</a></li>
                            </ul>
                        </div>
                        <div className="footer__nav-column">
                            <h4>About Us</h4>
                            <ul>
                                <li><a href="#about">Our Story</a></li>
                                <li><a href="#blog">Blog</a></li>
                                <li><a href="#careers">Careers</a></li>
                                <li><a href="#contact">Contact Us</a></li>
                            </ul>
                        </div>
                    </nav>
                </div>
                <div className="footer__bottom">
                    <p className="footer__copyright">© 2024 Elements Pay. All rights reserved.</p>
                    <div className="footer__social">
                        <a href="https://twitter.com" aria-label="Twitter">
                            <FontAwesomeIcon icon={faTwitter} />
                        </a>
                        <a href="https://github.com" aria-label="GitHub">
                            <FontAwesomeIcon icon={faGithub} />
                        </a>
                        <a href="https://linkedin.com" aria-label="LinkedIn">
                            <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
