import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGooglePlay } from '@fortawesome/free-brands-svg-icons';
import './Header.scss';
import Logo from '../../assets/icons/logo.png';

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        if (isMenuOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible';
        }

        return () => {
            document.body.style.overflow = 'visible';
        };
    }, [isMenuOpen]);

    return (
        <>
            <header className={`header ${isMenuOpen ? 'menu-open' : ''}`}>
                <div className="container header__container">
                    <div className="header__logo">
                        <img src={Logo} alt="elementsPay Logo" />
                    </div>
                    <button
                        className={`header__hamburger ${isMenuOpen ? 'is-active' : ''}`}
                        onClick={toggleMenu}
                        aria-label="Toggle menu"
                    >
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                    <nav className="header__nav header__nav--desktop">
                        <ul>
                            <li><a href="#features">Features</a></li>
                            <li><a href="#developers">Developers</a></li>
                            <li><a href="#docs">Docs</a></li>
                        </ul>
                        <button className="header__cta">
                            <FontAwesomeIcon icon={faGooglePlay} className="header__cta-icon" />
                            Download
                        </button>
                    </nav>
                </div>
            </header>
            <div className={`full-screen-nav ${isMenuOpen ? 'is-open' : ''}`}>
                <nav className="full-screen-nav__content">
                    <ul>
                        <li><a href="#features" onClick={toggleMenu}>Features</a></li>
                        <li><a href="#developers" onClick={toggleMenu}>Developers</a></li>
                        <li><a href="#docs" onClick={toggleMenu}>Docs</a></li>
                    </ul>
                    <button className="header__cta">
                        <FontAwesomeIcon icon={faGooglePlay} className="header__cta-icon" />
                        Download
                    </button>
                </nav>
            </div>
        </>
    );
};

export default Header;